<template>
  <div class="qrcode bg-white">
    <Row class="px-1">
      <i-Col span="24" class="align-justify">
        <Alert show-icon>
          小程序客服
          <template slot="desc">
            请前往微信公众平台绑定客服帐号，绑定之后可以点击登录
            <a
              href="https://mpkf.weixin.qq.com/"
              style="color:#2D8cF0"
            >网页端客服</a> 或使用微信扫描登录
            <a class="mpkf" @mouseenter="enter" @mouseleave="leave">移动端小程序客服</a> 进行客服沟通。目前小程序支持绑定不多于100个客服人员。
          </template>
        </Alert>
      </i-Col>
      <viewer :images="photo" v-show="qrState">
        <img v-for="src in photo" :src="src" :key="src" width="300" style="margin-right:300px;"/>
      </viewer>
      <!-- <i-Col span="24" class="align-justify">
        <Steps :current="current">
          <Step title="绑定客服人员" content="登录微信公众平台添加客服"></Step>
          <Step title="登录并接入" content="已被绑定的小程序客服人员登录后可选择接入对话"></Step>
          <Step title="切换客服状态" content="点击在线状态，可以选择在线状态、离开状态或退出登录"></Step>
          <Step title="接收消息" content="可设置为自动接入"></Step>
          <Step title="发送消息" content="客服人员可以对已经接入的会话进行对话"></Step>
        </Steps>
        <Button type="primary" @click="next">下一步</Button>
      </i-Col>
      <i-col span="24">
        <div v-if="current == 0">
          <img class="imgclass" src="../assets/kf/绑定客服.png" />
        </div>
        <div v-if="current == 1">
          <img class="imgclass" src="../assets/kf/登录网页客服.png" />
        </div>
        <div v-if="current == 2">
          <img class="imgclass" src="../assets/kf/选择状态.png" />
        </div>
        <div v-if="current == 3">
          <img class="imgclass" src="../assets/kf/接入设置.png" />
        </div>
        <div v-if="current == 4">
          <img class="imgclass" src="../assets/kf/发送消息.png" />
        </div>
      </i-col>-->
    </Row>
  </div>
</template>
<script>
export default {
  name: "mpsetting",
  data() {
    return {
      current: 0,
      theight: window.innerHeight - 275,
      photo: [
        "https://res.wx.qq.com/wxopenres/zh_CN/htmledition/comm_htmledition/res/entityshop/customer-service.jpg",
      ],
      qrState: false,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 275),
      false
    );
  },
  methods: {
    enter() {
      this.qrState = true;
    },
    leave() {
      this.qrState = false;
    },
    next() {
      if (this.current == 4) {
        this.current = 0;
      } else {
        this.current += 1;
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.time {
  font-size: 14px;
  font-weight: bold;
}
.content {
  padding-left: 5px;
}
</style>